<template>
<div class="search" :class="{'search-admin': !admin}">
    <v-text-field v-model="search" :label="$t('Search ...')" append-icon="mdi-magnify" class="mx-4" @input="getOrders($event)"></v-text-field>
</div>
</template>

<script>
import store from '@/store'
import {useVModel} from '@vueuse/core'
import {useRouter} from '@core/utils'
import {ref, computed} from "@vue/composition-api"
import {isHaveTabs} from "@/plugins/helpers"

export default {
    name: 'OrdersSearch',
    props: {
        admin: {
            type: Boolean,
            default: true
        },
        url: {
            type: String,
            default: false
        },
        userId: {
            type: String
        },
        storeId: {
            type: String
        }
    },
    setup(props, {
        emit
    }) {
        const timer = ref(null)
        const { route, router } = useRouter()
        const search = computed(({
            get() {
                if(isHaveTabs()) {
                    return store.state.pagination[route.value.name][route.value.params.tab].search
                }
                return store.state.pagination[route.value.name].search
            },
            set(val) {
                if(isHaveTabs()) {
                    let ob = {}
                    ob[route.value.params.tab] = {
                        search: val
                    }
                    store.commit("pagination/SET_" + route.value.name.replace(/-/gi, '_').toUpperCase() + "_PAGINATION", ob)
                }else {
                    store.commit("pagination/SET_" + route.value.name.replace(/-/gi, '_').toUpperCase() + "_PAGINATION", Object.assign({}, val, {
                        search: val
                    }))
                }
                router.replace({ name: route.value.name, query: { search: val }, params: {user_id: route.value.params.user_id, store_id: route.value.params.store_id} })
            }
        }))
        const getOrders = function (e) {
            if (timer.value) {
                clearTimeout(timer.value)
                timer.value = null
            }
            timer.value = setTimeout(() => {
                store.dispatch('order/getOrdersFromApi', {
                    url: props.url,
                    userId: props.userId,
                    storeId: props.storeId,
                    data: {
                        search: e,
                        page: 1
                    }
                })
            }, 1000)
        }
        return {
            getOrders,
            search
        }
    }
}
</script>
