<template>
  <div>
    <account-role-title role="Admin" />
    <!-- <breadcrumbs /> -->
    <span class="title d-block mb-2">{{ $t("Orders") }}</span>
    <div :class="$vuetify.theme.isDark ? 'v-orders-dark' : 'v-orders'">
      <div class="d-flex justify-end">
        <orders-search url="admin/orders" :userId="userId" :storeId="storeId"/>
      </div>
      <orders-table url="admin/orders" order-link="admin-order" :userId="userId" :storeId="storeId"></orders-table>
    </div>
  </div>
</template>

<script>
import ordersSearch from "@/views/cabinet/order/order-list/OrdersSearch";
import ordersTable from "@/views/cabinet/order/order-list/OrdersTable";
import { useRouter } from '@core/utils';
import { computed } from "@vue/composition-api/dist/vue-composition-api";

export default {
  name: "AdminOrdersList",
  components: {
    ordersSearch,
    ordersTable
  },
  setup() {
    const {route} = useRouter()
    return {
      userId: computed(() => route.value.params.user_id),
      storeId: computed(() => route.value.params.store_id),
    }
  }
}
</script>